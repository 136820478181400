.marketing-site-content-section {
  display: flex;
  flex-wrap: wrap;

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .content-img {
    //flex: 1;
  }

  .marketing-site-content-section-img {
    align-self: center;
    flex: 1 0 100%;

    img {
      width: 100%;
    }

    @include breakpoint(medium) {
      flex: 0 1 50%;
    }
    // @media screen and (min-width: 40em) {
    //   flex: 0 1 50%;
    // }
  }

  .marketing-site-content-section-block {
    padding: 1rem;
    flex: 1 0 100%;
    align-self: stretch;
    background: #2c3840;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-start;

    @include breakpoint(medium) {
      flex: 1 0 50%;
      padding: 2rem;
    }

    // @media screen and (min-width: 40em) {
    //   flex: 1 0 50%;
    //   padding: 2rem;
    // }
  }
}

.marketing-site-content-section-block-subheader {
  font-size: 13px;
}
