.featured-testimonials-section {
  //background: #e9e9e9;
  padding: 2rem 0.25rem;

  .featured-testimonials-container {
    .orbit-previous {
      color: $black;
      left: -1rem;
    }

    .orbit-next {
      color: $black;
      right: -1rem;
    }
  }

  .testimonial {
    display: flex;
    align-items: center;
    margin: 2rem 1rem;
  }

  .profile-pic {
    max-width: 100px;
    border-radius: 50%;
  }

  .featured-testimonials-quotation {
    margin-left: 3rem;
    margin-bottom: 0rem;
    padding: 1rem;
    position: relative;
    z-index: -1;

    &::before {
      content: "“";
      font-size: 5rem;
      color: lighten($black, 80%);
      position: absolute;
      top: -2.5rem;
      left: -1.5rem;
    }

    &::after {
      content: "”";
      font-size: 5rem;
      color: lighten($black, 80%);
      position: absolute;
      bottom: -4rem;
      right: -0.5rem;
    }

    @include breakpoint(small only) {
      margin-left: 0;
    }
  }
}
