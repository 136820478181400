$topbar-responsive-bg: #0066b3;
$topbar-responsive-animation-type: fade-in;

// .brandingandnav {
//   //max-width: 1024px;
//   margin: 0 auto !important;
//   //overflow: visible;
// }

@media screen and (max-width: 50em) {
  // .menuicon {
  // 	margin-right: 10%;
  // 	margin-top: 8%;
  // 	float: right;
  // }

  // span {
  // 	//position: absolute;
  // 	//right: 3%;
  // 	border: 1px solid $black;
  // 	border-radius: 5px;
  // 	padding: 0.25rem 0.25rem;
  // 	//top: 5%;
  // 	transform: translateY(-50%);

  // 	.menu-icon::after {
  // 		/* the block itself, the first line */
  // 		background: $black;
  // 	    /* middle and bottom */
  // 		box-shadow: 0 7px 0 $black, 0 14px 0 $black;
  // 	    /* respect WebKit */
  // 		-webkit-box-shadow: 0 7px 0 $black, 0 14px 0 $black;
  // 	}
  // }
  // ---------------------------
  // Vars & Helper Functions
  // ---------------------------

  :root {
    // --screen-width: 320px;
    // --screen-height: 560px;
    --header-bg-color: #673ab7;
    --splash-bg-color: #368887;
  }

  // calculate a circle's circumference based on radius
  @function circumference($r) {
    $pi: 3.141592653;
    @return 2 * $pi * $r;
  }

  //   overrides

  a.nav__toggle {
    color: black;
  }

  // ---------------------------
  // Main Navigation Menu
  // ---------------------------

  .nav {
    // Toggle Button
    &__toggle {
      display: inline-block;
      position: absolute;
      z-index: 10;
      padding: 0;
      border: 0;
      background: transparent;
      outline: 0;
      right: 15px;
      top: 15px;
      cursor: pointer;
      border-radius: 50%;
      transition: background-color 0.15s linear;

      &:hover,
      &:focus {
        //background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &__menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: var(--screen-height);
      position: relative;
      z-index: 5;
      visibility: hidden;
    }

    &__item {
      opacity: 0;
      transition: all 0.3s cubic-bezier(0, 0.995, 0.99, 1) 0.3s;
    }
    @for $i from 1 through 6 {
      &__item:nth-child(#{$i}) {
        transform: translateY(-40px * $i);
      }
    }

    &__link {
      color: white;
      display: block;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 1.25rem;
      text-decoration: none;
      padding: 1rem;

      &:hover,
      &:focus {
        outline: 0;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  // ---------------------------
  // SVG Menu Icon
  // ---------------------------

  .menuicon {
    display: block;
    cursor: pointer;
    //color: white;

    transform: rotate(0deg);
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &__bar,
    &__circle {
      fill: none;
      stroke: currentColor;
      stroke-width: 3;
      stroke-linecap: round;
    }
    &__bar {
      transform: rotate(0deg);
      transform-origin: 50% 50%;
      transition: transform 0.25s ease-in-out;
    }
    &__circle {
      transition: stroke-dashoffset 0.3s linear 0.1s;
      stroke-dashoffset: circumference(23); // 23 is the <circle>'s radius
      stroke-dasharray: circumference(23);
    }
  }

  // ---------------------------
  // Circular Splash Background
  // ---------------------------

  .splash {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 1px;
    height: 1px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 50%;
      //background-color: var(--splash-bg-color);
      background-color: $black;
      visibility: visible;

      // screen diameter can be 142vmax at most,
      // circle needs to be twice that size to cover it
      width: 284vmax;
      height: 284vmax;
      top: -142vmax;
      left: -142vmax;

      transform: scale(0);
      transform-origin: 50% 50%;
      transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);

      // will-change tells the browser we plan to
      // animate this property in the near future
      will-change: transform;
    }
  }

  // ---------------------------
  // Active State
  // ---------------------------

  .nav:target,
  .nav--open {
    //scale the background circle to full size
    > .splash::after {
      transform: scale(1);
    }

    //animate the menu icon
    .menuicon {
      color: white;
      transform: rotate(180deg);

      &__circle {
        stroke-dashoffset: 0;
      }
      &__bar:nth-child(1),
      &__bar:nth-child(4) {
        opacity: 0;
      }
      &__bar:nth-child(2) {
        transform: rotate(45deg);
      }
      &__bar:nth-child(3) {
        transform: rotate(-45deg);
      }
    }

    //show the nav items
    .nav {
      z-index: 10;
      &__menu {
        visibility: visible;
      }

      &__item {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .viewport {
    width: var(--screen-width);
    height: var(--screen-height);
    margin: 0 auto;
    position: relative;
    overflow: visible;
    background-color: white;
  }

  .header {
    height: 5.5rem;
    // background-color: #0066b3;
    // padding-bottom: 1rem;
  }

  .main {
    //padding: 20px;
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: 130px;
    grid-gap: 20px;

    &__item {
      height: 100%;
      background-color: #d8d8d8;

          &:hover,
      &:focus {
        background-color: #a4a4a4;
      }
    }
  }
}

.logotype {
  height: 70px;
  margin: 0.5rem 0 0 1rem;
}

@include breakpoint(medium) {
  .logotype {
    height: 70px;
    margin: 1rem 0 0 1rem;
  }
}

@include breakpoint(medium only) {
  // .logotype {
  //   margin: 1em 0 0 3rem;
  // }
  // .medium-nav {
  //   margin: 1rem 0 0 0;
  // }
  .menu {
    //margin-bottom: -2rem;
    margin-right: -5rem;
  }
}

.header {
  background-color: #0066b3;
  background-image: linear-gradient(#0041a3, #0066b3);
  padding-bottom: 1rem;
}

.topbar-responsive-button {
  color: $white;
  border-color: $white;
  border-radius: 5000px;
  //transition: color 0.15s ease-in, border-color 0.15s ease-in;

  &:hover {
    color: #fff;
    border-color: #fff;
  }

  @media screen and (max-width: 39.9375em) {
    //width: 30%;
    margin: 0.875rem 0;
  }
}

.button.hollow {
  border: 1px solid white;
}

.button a {
  color: #fff;
}

.nav__item {
  margin: 0 auto;
}
