.sellsheetcontainer {
  padding-left: 4%;
  padding-top: 2.25em;
  padding-bottom: 1em;
  //padding:2em 0 1em 4%;
  background-color: #e9e9e9;
}

.sellitem {
  padding-top: 1em;
}

@include breakpoint(small only) {
  /*.sellsheetcontainer{
       padding:1em 0 1em 4%;
       
    }*/

  .sellitem {
    padding-right: 1.75em;
  }
}
