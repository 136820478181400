/*.container{
	padding: 80px 2rem 1rem;
} */

.centered {
  width: 100%;
  margin: 0 auto !important;
  //background-color: #e6e6e6;
}

//padding
.padding-left-1em {
  padding-left: 1em;
}

.pad1em {
  padding: 1em;
}

//floats
.float-right {
  float: right;
}

.white {
  color: white;
}

.navitems {
  padding: 2em;
  margin: 0;
  list-style: none;
  position: relative;
}

.menu-icon {
  margin-bottom: 4px;
}

.border {
  border: 1px solid red;
}

.button.round {
  border-radius: 5000px;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0;
  background-color: #0066b3;
}
//hero

.marketing-header {
  font-size: 50px;
  color: white;
  font-weight: 800;
  line-height: 1.1;
  text-transform: uppercase;
}

.sellsheetcontainer.row {
  margin-left: 0;
}

//typography

//footer

/*.marketing-site-footer {
    padding:1em 0 0;
    .container{
        padding-left:1em;
    }
}*/

.grid-x.grid-container.brandingandnav {
  margin: 0 0 1em 4%;
  padding: 0;
}

.features-container {
  padding-top: 100px;
  padding-left: 1em;
}

.featurelist {
  padding: 1em 0;
}

.aboutcontainer {
  padding: 100px 2em;
}

.faqcontainer {
  padding: 2em 2em;
}

.supportblock {
  //   margin: 0em 1em;
  padding-top: 100px;
}

.menu a {
  color: white;
}

.menu.simple {
  color: white;
}

.marketing-site-footer .container {
  margin-left: 3%;
}

.marketing-site-footer-title {
  padding-left: 0.75em;
}

// icons
.sticky {
  position: fixed !important;
  width: 100%;
}

// @include breakpoint(medium) {
//   .sticky {
//     width: 1024px;
//   }
// }

/* link styles ===========
========================*/

/*
=====
DEMO DEPENDENCIES
=====
*/

/* The component will reset button browser styles */

.r-button {
  --uirButtonBackgroundColor: var(--rButtonBackgroundColor, transparent);
  --uirButtonPadding: var(
    --rButtonPadding,
    var(--rButtonPaddingTop, 0) var(--rButtonPaddingRight, 0)
      var(--rButtonPaddingBottom, 0) var(--rButtonPaddingLeft, 0)
  );
  --uirButtonBorderWidth: var(--rButtonBorderWidth, 0);
  --uirButtonBorderStyle: var(--rButtonBorderWidth, solid);
  --uirButtonBorderColor: var(--rButtonBorderColor, currentColor);
  --uirButtonFontFamily: var(--rButtonFontFamily, inherit);
  --uirButtonFontSize: var(--rButtonFontSize, inherit);
  --uirButtonColor: var(--rButtonColor);

  background-color: var(--uirButtonBackgroundColor);
  padding: var(--uirButtonPadding);

  border-width: var(--uirButtonBorderWidth);
  border-style: var(--uirButtonBorderStyle);
  border-color: var(--uirButtonBorderColor);

  cursor: pointer;

  font-family: var(--uirButtonFontFamily);
  font-size: var(--uirButtonFontSize);
}

.r-button::-moz-focus-inner,
.r-button[type="button"]::-moz-focus-inner,
.r-button[type="reset"]::-moz-focus-inner,
.r-button[type="submit"]::-moz-focus-inner {
  /* Remove the inner border and padding in Firefox. */

  border-style: none;
  padding: 0;
}

/* The component will reset browser's styles of link */

.r-link {
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: var(--rLinkTextColor);
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);
  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}

/*
=====
CORE STYLES
=====
*/

.ai-element {
  --uiaiElementDisplay: var(--aiElementDisplay, inline-flex);
  --uiaiElementPadding: var(
    --aiElementPadding,
    var(--aiElementPaddingTop, 0) var(--aiElementPaddingRight, 0)
      var(--aiElementPaddingBottom, 0) var(--aiElementPaddingLeft, 0)
  );
  --uiaiElementColor: var(--aiElementColor);

  display: var(--uiaiElementDisplay);
  position: relative;
  overflow: hidden;
}

a.ai-element {
  padding: var(--uiaiElementPadding);
}

button.ai-element {
  --rButtonPadding: var(--uiaiElementPadding);
}

.ai-element__label {
  color: var(--uiaiElementColor);
}

/* 
Styles of elements with the bottom border
*/

.ai-element_type2 {
  --uiaiElementLineWeight: var(--aiElementLineWeight, 1px);
  --uiaiElementLineColor: var(--aiElementLineColor, #fff);
  --aiElementPaddingBottom: var(--uiaiElementLineWeight);

  position: relative;
}

.ai-element_type2::after {
  content: "";
  width: 100%;
  height: var(--uiaiElementLineWeight);
  background-color: var(--uiaiElementLineColor);

  position: absolute;
  left: 0;
  bottom: 0;
}

/* 
Styles of elements with background
*/

.ai-element_type3 {
  --uiaiElementLineColor: var(--aiElementLineColor, #000);
  --uiaiElementPadding: var(--aiElementPadding, 10px);
  transition: color 0.3s ease-out;
  z-index: 1;
}

.ai-element_type3::before,
.ai-element_type3::after {
  content: "";
  background-color: var(--uiaiElementLineColor);
  opacity: 1;
  position: absolute;
  z-index: -1;

  transition: transform 0.35s ease-out, opacity 0.35s ease-out 0.03s;
}

.ai-element_type3:hover::before,
.ai-element_type3:hover::after {
  opacity: 0;
  transition-delay: 0s;
}

.ai-element_text::before {
  content: attr(data-ai-element-text);
  color: var(--uiaiElementColorHover);
  position: absolute;
}

.ai-element_text::before,
.ai-element_text .ai-element__label {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.86, 0.6, 0.08, 1.01);
  transition-duration: 0.4s;
}

.ai-element_text:hover::before,
.ai-element_text:hover .ai-element__label {
  transition-duration: 0.3s;
}

/*
=====
EFFECTS
=====
*/

/* effect 1 */

.ai-element1::before,
.ai-element1::after,
.ai-element1 .ai-element__label::before,
.ai-element1 .ai-element__label::after {
  transform: translate3d(0, 0, 0);
  transition-timing-function: ease-out;
  transition-duration: 0.2s, 0.15s;
}

.ai-element1:hover::before,
.ai-element1:hover::after,
.ai-element1:hover .ai-element__label::before,
.ai-element1:hover .ai-element__label::after {
  transition-duration: 0.25s;
}

.ai-element1:hover::before {
  transform: translate3d(-105%, 0, 0);
}

.ai-element1:hover::after {
  transform: translate3d(105%, 0, 0);
}

.ai-element1:hover .ai-element__label::before {
  transform: translate3d(0%, -100%, 0);
}

.ai-element1:hover .ai-element__label::after {
  transform: translate3d(0%, 100%, 0);
}

/* effect 2 */

.ai-element2::after {
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-in;
}

.ai-element2:hover::after {
  transform: translate3d(-100%, 0, 0);
}

/* effect 3 */

.ai-element3::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.ai-element3:hover::after {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

/* effect 4 */

.ai-element4::before,
.ai-element4::after,
.ai-element4 .ai-element__label::before,
.ai-element4 .ai-element__label::after {
  transition-timing-function: ease-out;
  transition-duration: 0.2s, 0.2s;
  transform: translate3d(0, 0, 0);
}

.ai-element4:hover::before,
.ai-element4:hover::after,
.ai-element4:hover .ai-element__label::before,
.ai-element4:hover .ai-element__label::after {
  transition-delay: 0s, 0.05s;
}

.ai-element4:hover::before {
  transform: translate3d(-100%, 0, 0);
}

.ai-element4:hover::after {
  transform: translate3d(100%, 0, 0);
}

.ai-element4:hover .ai-element__label::before {
  transform: translate3d(0, 100%, 0);
}

.ai-element4:hover .ai-element__label::after {
  transform: translate3d(0, -100%, 0);
}

/* effect 5 */

.ai-element5::before,
.ai-element5::after,
.ai-element5 .ai-element__label::before,
.ai-element5 .ai-element__label::after {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transform: translate3d(0, 0, 0);
}

.ai-element5::before,
.ai-element5::after {
  width: 100%;
  height: var(--uiaiElementLineWeight);
  left: 0;
}

.ai-element5 .ai-element__label::before,
.ai-element5 .ai-element__label::after {
  width: var(--uiaiElementLineWeight);
  height: 100%;
  top: 0;
}

.ai-element5::before {
  top: 0;
}

.ai-element5::after {
  bottom: 0;
}

.ai-element5 .ai-element__label::before {
  left: 0;
}

.ai-element5 .ai-element__label::after {
  right: 0;
}

.ai-element5:hover::before,
.ai-element5:hover .ai-element__label::before {
  transition-delay: 0s;
}

.ai-element5::after,
.ai-element5:hover .ai-element__label::after {
  transition-delay: 0.25s;
}

.ai-element5 .ai-element__label::after,
.ai-element5:hover::after {
  transition-delay: 0.15s;
}

.ai-element5 .ai-element__label::before,
.ai-element5:hover::before {
  transition-delay: 0.35s;
}

.ai-element5:hover::before {
  transform: translate3d(-105%, 0, 0);
}

.ai-element5:hover::after {
  transform: translate3d(105%, 0, 0);
}

.ai-element5:hover .ai-element__label::before {
  transform: translate3d(0, 105%, 0);
}

.ai-element5:hover .ai-element__label::after {
  transform: translate3d(0, -105%, 0);
}

/* effect 6 */

.ai-element6::before,
.ai-element6::after {
  width: 50%;
  height: 100%;
  top: 0;
  transform: translate3d(0, 0, 0);
}

.ai-element6::before {
  left: 0;
}

.ai-element6::after {
  right: 0;
}

.ai-element6:hover::before {
  transform: translate3d(-100%, 0, 0);
}

.ai-element6:hover::after {
  transform: translate3d(100%, 0, 0);
}

/* effect 7 */

.ai-element7::before,
.ai-element7::after {
  width: 100%;
  height: 50%;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.ai-element7::before {
  top: 0;
}

.ai-element7::after {
  bottom: 0;
}

.ai-element7:hover::before {
  transform: translate3d(0, -50%, 0);
}

.ai-element7:hover::after {
  transform: translate3d(0, 50%, 0);
}

/* effect 8 */

.ai-element8::before,
.ai-element8::after {
  width: 51%;
  height: 100%;
  transform: rotate(0);
  top: 0;
}

.ai-element8::before {
  left: 0;
}

.ai-element8::after {
  right: 0;
}

.ai-element8:hover::before,
.ai-element8:hover::after {
  transform: rotate(360deg);
}

/* effect 9 */

.ai-element9::before,
.ai-element9::after {
  width: 51%;
  height: 100%;
  top: 0;
  transform: translate3d(0, 0, 0);
}

.ai-element9::before {
  left: 0;
}

.ai-element9::after {
  right: 0;
}

.ai-element9:hover::before {
  transform: translate3d(-100%, 0, 0) rotate(-45deg);
}

.ai-element9:hover::after {
  transform: translate3d(100%, 0, 0) rotate(-45deg);
}

/*
=====
DEMO STYLES
=====
*/

/*body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Open Sans",
    "Helvetica Neue", "Segoe UI", "Arial", sans-serif;
  font-size: 16px;
  margin: 0;
}

@media (min-width: 1200px) {
  /*body{
  display: grid;
  grid-template-columns: repeat(3, minmax(240px, 1fr));
 }	
}*/
.section {
  display: flex;
  min-height: 300px;
  text-align: center;

  font-size: 17px;
  font-weight: 700;
}

.section:nth-child(2n + 1) {
  background-color: #f1f4fa;
}

.section__item {
  margin: auto;
}

.section__box:nth-child(n + 2) {
  margin-top: 40px;
}

.ai-element__label {
  --uiaiElementColorHover: var(--aiElementColorHover);
  font-weight: 700;
  text-transform: uppercase;
}

.ai-element:hover .ai-element__label {
  color: var(--uiaiElementColorHover);
}

/*
=====
SETTINGS
=====
*/

.ai-element {
  --aiElementLineWeight: 1px;
  --aiElementLineColor: #000;
  --aiElementColor: #1b255a;
  --aiElementColorHover: #243aab;
}

.ai-element_type1,
.ai-element_type3 {
  --aiElementPadding: 10px 15px;
}

.ai-element_type3 {
  --aiElementColor: #fff;
}
