@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

.typeLato {
    font-family: 'Lato', sans-serif;
}

.typeOpen_Sans {
    font-family: 'Open Sans', sans-serif;
}

.bold{
    font-weight:700;
}

.uppercase {
	text-transform: uppercase;
}