$marketing-site-three-up-width: 900px;

.marketing-site-three-up {
  text-align: center;
  padding: 2rem 1rem;

  .row {
    max-width: $marketing-site-three-up-width;
  }

  .columns {
    margin-bottom: 1rem;
    @media screen and (min-width: 40em) {
      margin-bottom: 0;
    }
  }

  .fa {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: $primary-color;
  }
}

.marketing-site-three-up-headline {
  color: #0066b3;
  text-align: left;
  //margin-bottom: 2rem;
  // font-size: 24px;
}

@include breakpoint(medium) {
  .marketing-site-three-up-headline {
    text-align: center;
    //margin-bottom: 2rem;
    // font-size: 24px;
  }
}

h4.mstut {
  text-transform: uppercase;
  font-size: 1.5rem;
  overflow: visible !important;
  color: #0066b3;
  font-weight: bold;
}

// .icon-mstu {
//   margin-bottom: 1em;
// }

.card {
  margin-right: 0.75rem;
  max-width: 50%;
  border: none;
}
