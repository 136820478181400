$marketing-site-hero-height: 65vh;

.hero {
  background: url("/assets/img/istockphoto-1251508266-1024hero.jpg") center top
    no-repeat;
  background-size: cover;
  height: 30rem;
  position: relative;
  z-index: -1;
}

.button.hero-btn {
  float: left;
  margin-right: 5px;
}

.hero-content {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.hero-text {
  background-color: rgba(0, 0, 0, 0.7);
  //margin-top: 13.5rem;
  padding: 1em;
}

.subheading1 {
  font-size: x-small;
  padding-left: 1em;
}

.vd4screens {
  padding-top: 0.5rem;
  // img {
  //   width: 100%;
  // }
}

.vd4screens-sm {
  width: 100%;
}

.vd4screens-md {
  float: right;
}

@include breakpoint(small) {
  .hero-heading {
    font-size: x-large;
    padding-right: 1em;
  }
}

@include breakpoint(medium) {
  .hero-text {
    //margin-top: 17rem;
    padding: 1em 2em;
  }
  // background: url("/assets/img/Four-Screens-hero4md.png") no-repeat center
  //   right;
  // background-size: 25% contain;
}

@include breakpoint(large) {
  .vd4screens-lg {
    position: relative;
    img {
      object-fit: contain;
      height: 100%;
    }
  }
}
