$translucent-form-overlay-max-width: 100%;

.translucent-form-overlay {
  max-width: $translucent-form-overlay-max-width;
  width: 100%;
  background-color: rgba(54, 54, 54, 0.8);
  padding: 20px;
  color: $white;

  // workaround 6.3.0 bug
  .columns.row {
    display: block;
  }

  h3,
  label {
    color: $white;
  }
  input,
  select {
    color: black;
  }
  input::placeholder {
    color: $dark-gray;
  }
}
